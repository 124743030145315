// general JS (always add)
import '@mod-mijnsiteonline/nodejs/mso';

// main CSS
import './sion.scss';
import 'font-awesome/css/font-awesome.min.css';

// template specific JS files
import $ from 'jquery';
window.jQuery = $;
require('./web/js/jquery.dropotron.min.js');
require('./web/js/jquery.scrollex.min.js');
window.skel = require('./web/js/skel.min.js');
require('./web/js/util.js');
import './web/js/main';
