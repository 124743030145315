// import WhBase from '@mod-system/js/compat/base';
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';


import $ from 'jquery';
window.jQuery = $;
window.skel = require('./skel.min.js');
require('./jquery.scrollex.min.js');

require('../../node_modules/sticky-sidebar/dist/sticky-sidebar.js');

dompack.onDomReady(() => {
	if (whintegration.config.obj.isroodbosch === true) {
		if (dompack.qS('#pagecontent-sidebar')) {
			let sidebar = new StickySidebar('#pagecontent-sidebar', {
		    containerSelector: '#pagecontent',
		    innerWrapperSelector: '.pagecontent__sidebar-inner',
		    topSpacing: 170,
		  });
	  }
  }

	var settings = {

		banner: {

			// Indicators (= the clickable dots at the bottom).
				indicators: true,

			// Transition speed (in ms)
			// For timing purposes only. It *must* match the transition speed of "#banner > article".
				speed: 1500,

			// Transition delay (in ms)
				delay: 5000,

			// Parallax intensity (between 0 and 1; higher = more intense, lower = less intense; 0 = off)
				parallax: 0.25

		}

	};

	skel.breakpoints({
		xlarge:	'(max-width: 1680px)',
		large:	'(max-width: 1280px)',
		medium:	'(max-width: 980px)',
		small:	'(max-width: 736px)',
		xsmall:	'(max-width: 480px)'
	});

	/**
	 * Applies parallax scrolling to an element's background image.
	 * @return {jQuery} jQuery object.
	 */
	$.fn._parallax = (skel.vars.browser == 'ie' || skel.vars.mobile) ? function() { return $(this) } : function(intensity) {

		var	$window = $(window),
			$this = $(this);

		if (this.length == 0 || intensity === 0)
			return $this;

		if (this.length > 1) {

			for (var i=0; i < this.length; i++)
				$(this[i])._parallax(intensity);

			return $this;

		}

		if (!intensity)
			intensity = 0.25;

		$this.each(function() {

			var $t = $(this),
				on, off;

			on = function() {

				$t.css('background-position', 'center 100%, center 100%, center 0px');

				$window
					.on('scroll._parallax', function() {

						var pos = parseInt($window.scrollTop()) - parseInt($t.position().top);

						$t.css('background-position', 'center ' + (pos * (-1 * intensity)) + 'px');

					});

			};

			off = function() {

				$t
					.css('background-position', '');

				$window
					.off('scroll._parallax');

			};

			skel.on('change', function() {

				if (skel.breakpoint('medium').active)
					(off)();
				else
					(on)();

			});

		});

		$window
			.off('load._parallax resize._parallax')
			.on('load._parallax resize._parallax', function() {
				$window.trigger('scroll');
			});

		return $(this);

	};

	/**
	 * Custom banner slider for Slate.
	 * @return {jQuery} jQuery object.
	 */
	$.fn._slider = function(options) {
		var	$window = $(window),
			$this = $(this);

		if (this.length == 0)
			return $this;

		if (this.length > 1) {

			for (var i=0; i < this.length; i++)
				$(this[i])._slider(options);

			return $this;

		}

		// Vars.
			var	current = 0, pos = 0, lastPos = 0,
				slides = [], indicators = [],
				$indicators,
				$slides = $this.children('article'),
				intervalId,
				isLocked = false,
				i = 0;

		// Turn off indicators if we only have one slide.
			// if ($slides.length == 1)
			// 	options.indicators = false;

		// Functions.
		// 	$this._switchTo = function(x, stop) {

		// 		if (isLocked || pos == x)
		// 			return;

		// 		isLocked = true;

		// 		if (stop)
		// 			window.clearInterval(intervalId);

		// 		// Update positions.
		// 			lastPos = pos;
		// 			pos = x;

		// 		// Hide last slide.
		// 			slides[lastPos].removeClass('top');

		// 			if (options.indicators)
		// 				indicators[lastPos].removeClass('visible');

		// 		// Show new slide.
		// 			slides[pos].addClass('visible').addClass('top');

		// 			if (options.indicators)
		// 				indicators[pos].addClass('visible');

		// 		// Finish hiding last slide after a short delay.
		// 			window.setTimeout(function() {

		// 				slides[lastPos].addClass('instant').removeClass('visible');

		// 				window.setTimeout(function() {

		// 					slides[lastPos].removeClass('instant');
		// 					isLocked = false;

		// 				}, 100);

		// 			}, options.speed);

		// 	};

		// // Indicators.
		// 	if (options.indicators)
		// 		$indicators = $('<ul class="indicators"></ul>').appendTo($this);

		// Slides.
			$slides
				.each(function() {

					var $slide = $(this),
						$img = $slide.find('img');

					// Slide.
						$slide
							.css('background-image', 'url("' + $img.attr('src') + '")')
							.css('background-position', ($slide.data('position') ? $slide.data('position') : 'center'));

					// Add to slides.
						slides.push($slide);

					// Indicators.
						// if (options.indicators) {

						// 	var $indicator_li = $('<li>' + i + '</li>').appendTo($indicators);

						// 	// Indicator.
						// 		$indicator_li
						// 			.data('index', i)
						// 			.on('click', function() {
						// 				$this._switchTo($(this).data('index'), true);
						// 			});

						// 	// Add to indicators.
						// 		indicators.push($indicator_li);

						// }

					i++;

				})
				._parallax(options.parallax);

		// Initial slide.
			// slides[pos].addClass('visible').addClass('top');

			// if (options.indicators)
			// 	indicators[pos].addClass('visible');

		// Bail if we only have a single slide.
			// if (slides.length == 1)
			// 	return;

		// Main loop.
			intervalId = window.setInterval(function() {

				current++;

				if (current >= slides.length)
					current = 0;

				// $this._switchTo(current);

			}, options.delay);

	};

	$(function() {

		var	$window = $(window),
			$body = $('body'),
			$header = $('#header'),
			$banner = $('.mso-header-slideshow');

		// Disable animations/transitions until the page has loaded.
//			$body.addClass('is-loading');
//
//			$window.on('load', function() {
//				window.setTimeout(function() {
//					$body.removeClass('is-loading');
//				}, 100);
//			});

		// Mobile?
			if (skel.vars.mobile)
				$body.addClass('is-mobile');
			else
				skel
					.on('-medium !medium', function() {
						$body.removeClass('is-mobile');
					})
					.on('+medium', function() {
						$body.addClass('is-mobile');
					});

		// Fix: Placeholder polyfill.
			$('form').placeholder();

		// Fix: Post wrapping on IE<10.
			if (skel.vars.IEVersion < 10)
				$('.posts > .post:nth-child(3n + 1)')
					.addClass('first');

		// Prioritize "important" elements on medium.
			// skel.on('+medium -medium', function() {
			// 	$.prioritize(
			// 		'.important\\28 medium\\29',
			// 		skel.breakpoint('medium').active
			// 	);
			// });

		// Dropdowns.
			$('#nav > ul').dropotron({
				alignment: 'center',
				hideDelay: 400
			});

		// Header.
			if (skel.vars.IEVersion < 9)
				$header.removeClass('alt');

			if ($banner.length > 0
			&&	$header.hasClass('alt')) {

				$window.on('resize', function() { $window.trigger('scroll'); });

				$banner.scrollex({
					bottom:		$header.outerHeight(),
					terminate:	function() { $header.removeClass('alt'); },
					enter:		function() { $header.addClass('alt'); },
					leave:		function() { $header.removeClass('alt'); $header.addClass('reveal'); }
				});

			}

		// Banner.
			$banner._slider(settings.banner);

		// Off-Canvas Navigation.

			// Navigation Panel Toggle.
				$('<a href="#navPanel" class="navPanelToggle"></a>')
					.appendTo($header);

			// Navigation Panel.
				$(
					'<div id="navPanel">' +
						'<nav>' +
							$('#nav').navList() +
						'</nav>' +
						'<a href="#navPanel" class="close"></a>' +
					'</div>'
				)
					.appendTo($body)
					.panel({
						delay: 500,
						hideOnClick: true,
						hideOnSwipe: true,
						resetScroll: true,
						resetForms: true,
						side: 'right'
					});

			// Fix: Remove transitions on WP<10 (poor/buggy performance).
				if (skel.vars.os == 'wp' && skel.vars.osVersion < 10)
					$('#navPanel')
						.css('transition', 'none');

	});
});
